import React, {useContext, useState, useEffect} from 'react';
import { useRouter } from 'next/router';

type LayoutState = {
  activeTab: string,
  setActiveTab: (val: string, options?: {scroll?: boolean, changeRouterState?: boolean}) => void,
  isMainMenuOpen: boolean,
  setIsMainMenuOpen: (val: boolean) => void
};

const initialState = {
  activeTab: '',
  setActiveTab: () => {},
  isMainMenuOpen: false,
  setIsMainMenuOpen: () => {}
};

const LayoutContext = React.createContext<LayoutState>(initialState);

type Props = {
  children?: React.ReactNode
};

const LayoutProvider: React.FC<Props> = (props) => {

  const router = useRouter();

  const [activeTab, setActiveTabInternal] = useState<string>(initialState.activeTab);
  const [isMainMenuOpen, setIsMainMenuOpenInternal] = useState<boolean>(false);


  const setTabFromUrl = () => {
  
    const tab = router.query.tab?.[0] ?? '';
    const tabName = tab === '' ? 'intro' : tab;

    setActiveTabInternal(tabName);

    if(typeof window !== 'undefined'){

      const el = document.querySelector(`#homeSection-${tabName}`) as HTMLElement;

      if(el){

        setTimeout(() => {
          el.scrollIntoView({ 
            behavior: 'smooth',
            block: `start`
          });
        }, 100);

      }

    }
  
  }

  // When the router loads, set the active tab
  useEffect(() => {

    if(!router.isReady){ return; }

    setTabFromUrl();

  }, [router.isReady]);


  // Set the active tab
  const setActiveTab = (val: string, options?: {scroll?: boolean, changeRouterState?: boolean}) => {

    setActiveTabInternal(val);

    if(options?.changeRouterState !== false){
      const urlTab = val === 'intro' ? '' : val;
      router.push(`/${urlTab}`, `/${urlTab}`, {shallow: true});
    }

    if(typeof window !== 'undefined'){

      const tabName = val === '' ? 'intro' : val;

      const el = document.querySelector(`#homeSection-${tabName}`) as HTMLElement;

      if(el && options?.scroll !== false){
        el.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }

    }

  };

  const setIsMainMenuOpen = (val: boolean) => {
    setIsMainMenuOpenInternal(val);
  };

  const ctx: LayoutState = {
    activeTab,
    setActiveTab,
    isMainMenuOpen,
    setIsMainMenuOpen
  }

  return (
    <LayoutContext.Provider value={ctx}>
      <>{props.children}</>
    </LayoutContext.Provider>  
  )

}

const useLayout = () => {
  const context = useContext(LayoutContext)
  return context
}

export type { LayoutState }
export default useLayout
export { LayoutContext, LayoutProvider }